@use "@angular/material" as mat;
// 1rem = 16px (use this for converting anything to px on this file)

.h1 {
    font-size: 3rem; //48px
    font-weight: 700;
    line-height: 3.5rem; //56px
    letter-spacing: 0;
}

.h2 {
    font-size: 2.25rem; //36px
    font-weight: 700;
    line-height: 2.625rem; //42px
    letter-spacing: 0;
}

.h3 {
    font-size: 1.5rem; //24px
    font-weight: 700;
    line-height: 2rem; //32px
    letter-spacing: 0;
}

.h4 {
    font-size: 1rem; //16px
    font-weight: 700;
    line-height: 1.5rem; //24px
    letter-spacing: 0;
}

.h5 {
    font-size: 0.875rem; //14px
    font-weight: 700;
    line-height: 1.25rem; //20px
    letter-spacing: 0;
}

.h6 {
    font-size: 0.75rem; //12px
    font-weight: 700;
    line-height: 1rem; //16px
    letter-spacing: 0.0375rem;
    text-transform: uppercase;
}

.body-large {
    font-size: 1rem; //16px
    line-height: 1.5rem; //24px
    letter-spacing: 0;
}

.body {
    font-size: 0.875rem; //14px
    line-height: 1.25rem; //20px
    letter-spacing: 0;
}

.label {
    font-size: 0.75rem; //12px
    line-height: 1rem; //16px
    letter-spacing: 0;
    font-weight: 500;

}

.caption {
    font-size: 0.75rem; //12px
    line-height: 1rem; //16px
    letter-spacing: 0;
}

.link {
    font-size: 0.875rem; //14px
    line-height: 1.25rem; //20px
    letter-spacing: 0;
}

.h20 {
    font-size: 1.25rem; //20px
    color: var(--color-black);
    font-weight: 700;
    line-height: 1rem; //16px
    letter-spacing: 0;
}

.black {
    color: var(--color-black);
}

.black-text {
    color: var(--color-black-text);
}

.teal {
    color: var(--color-teal);
}

.gray {
    color: var(--color-gray-1000);
}

.primary {
    color: var(--primary-color-light);
}

.yellow {
    color: var(--color-yellow-dark);
}

.warn {
    color: var(--warning-color-dark);
}

.white {
    color: var(--color-white);
}

.success {
    color: var(--success-color-dark);
}

.gray-light{
    color: var(--color-gray-400);
}

.accent {
    color: var(--accent-color-dark);
}

.amber-yellow {
  color: var(--color-yellow-amber);
}

.azure-blue {
  color: var(--color-blue);
}

.hot-pink {
  color: var(--color-hot-pink);
}

$codes: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 !default;
$color-palettes: (
  "green": $default-success,
  "yellow": $default-yellow,
  "warn": $default-warn,
  "primary": $default-primary
) !default;

@each $color-name, $color-value in $color-palettes {
  @each $code in $codes {
    .#{$color-name}-#{$code} {
      color: mat.get-color-from-palette($color-value, $code);
    }
    .bg-#{$color-name}-#{$code} {
      background: mat.get-color-from-palette($color-value, $code);
    }
  }
}
